import React, { useEffect, useMemo, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useRevalidator,
} from "@remix-run/react";
import {
  Bars3Icon,
  CalendarIcon,
  ChevronUpDownIcon,
  CogIcon,
  DocumentTextIcon,
  HomeIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { Logo } from "~/components/icons";
import {
  Avatar,
  AvatarImage,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  cn,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Sheet,
  SheetContent,
  SheetTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useToast,
} from "@mindstonehq/ui";
import {
  GraduationCapIcon,
  HelpCircleIcon,
  LogOutIcon,
  MessageCircleWarningIcon,
  SearchIcon,
  SettingsIcon,
  Sparkles,
  TargetIcon,
} from "lucide-react";
import {
  SpaceMetadataFragment,
  useMindspaceJoinMutation,
} from "~/graphql/__generated";
import { useConfig } from "~/hooks/webconfig";
import { useIsAuthenticated, User, useUser } from "~/hooks/context-hook";
import Notifications from "~/components/notifications/notifications";
import {
  useRegisterNavigationCommandPalette,
  useToggleCommandPalette,
} from "~/components/command-palette/command-palette-context";

export interface NavigationItem {
  name: string;
  href?: string;
  icon: any;
  current?: (path: string) => boolean;
  available: (space: SpaceMetadataFragment, user?: User) => boolean;
  action?: () => any;
}

export default function PageLayout({
  spaces,
  currentSpace,
  children,
}: React.PropsWithChildren<{
  spaces: SpaceMetadataFragment[];
  currentSpace: SpaceMetadataFragment;
}>) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [container, setContainer] = useState<any>(null);
  const location = useLocation();
  const toggleCommandPalette = useToggleCommandPalette();
  const [joinMindspaceMutation] = useMindspaceJoinMutation();
  const { revalidate } = useRevalidator();
  const { toast } = useToast();
  const user = useUser();
  const authenticated = useIsAuthenticated();
  const config = useConfig();
  const navigate = useNavigate();
  const registerNavigation = useRegisterNavigationCommandPalette();
  const navigation = useMemo<NavigationItem[]>(
    () => [
      {
        name: "Home",
        href: "/",
        icon: HomeIcon,
        current: (path: string) => path === "/",
        available: (_, user) => !!user,
      },
      {
        name: "Programs",
        href: "/programs",
        icon: GraduationCapIcon,
        current: (path: string) => path.startsWith("/programs"),
        available: (space) => space.modules.programs,
      },
      {
        name: "Sandbox",
        href: "/sandbox",
        icon: Sparkles,
        current: (path: string) => path.startsWith("/sandbox"),
        available: (space, user) =>
          (space.modules.sandbox || !!space.programs?.enrolled.total) && !!user,
      },
      {
        name: "Events",
        href: "/events",
        icon: CalendarIcon,
        current: (path: string) => path.startsWith("/events"),
        available: (space) => space.modules.events,
      },
      {
        name: "Content",
        href: "/content",
        icon: DocumentTextIcon,
        current: (path: string) => path.startsWith("/content"),
        available: (_, user) => !!user,
      },
      {
        name: "Members",
        href: "/members",
        icon: UserGroupIcon,
        current: (path: string) => path.startsWith("/members"),
        available: (_, user) => !!user,
      },
      {
        name: "Admin",
        href: "/admin",
        icon: SettingsIcon,
        current: (path: string) => path.startsWith("/admin"),
        available: (space) => space.currentUserPermission?.role === "admin",
      },
    ],
    [user],
  );

  useEffect(() => {
    registerNavigation(navigation);
  }, [navigation, registerNavigation]);

  return (
    <div
      className={
        "flex h-[100dvh] overflow-y-hidden w-screen flex-col md:flex-row"
      }
    >
      <div className="sticky dark top-0 w-full z-40 h-16 shrink-0 flex justify-between items-center gap-x-6 bg-card  text-foreground px-4 py-4 sm:px-6 md:hidden border-b shadow border-border">
        <div className="flex flex-row shrink-0 space-x-2 items-center">
          <Sheet open={sidebarOpen} onOpenChange={setSidebarOpen}>
            <SheetTrigger asChild>
              <Button variant={"ghost"} className="-m-2.5 p-2.5 xlhidden">
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" />
              </Button>
            </SheetTrigger>
            <SheetContent className="dark bg-card text-foreground" side="left">
              {sidebarOpen && (
                <div className="flex bg-card h-full grow flex-col gap-y-5 overflow-y-autopx-6 pb-2">
                  <div className="flex mt-3 text-xl font-medium shrink-0">
                    <Link to={"/"}>
                      <Logo className="h-6" />
                    </Link>
                  </div>
                  <nav className="flex flex-1 flex-col mt-6 grow">
                    <div
                      role="list"
                      className="flex flex-1 flex-col gap-y-7 list-none"
                    >
                      <div
                        role="list"
                        className="flex flex-col gap-y-2 list-none"
                      >
                        {navigation
                          .filter((x) => x.available(currentSpace, user))
                          .map((item) => (
                            <MenuItem
                              item={item}
                              key={item.href}
                              closeSidebar={() => setSidebarOpen(false)}
                            />
                          ))}
                      </div>
                    </div>
                  </nav>
                  {authenticated &&
                    !spaces.find((x) => x.id === currentSpace.id) && (
                      <Card className="light">
                        <CardHeader className="p-3">
                          <CardTitle className="flex flex-row items-center gap-1 text-warning">
                            <MessageCircleWarningIcon className="h-5" />
                            <span>Not a member</span>
                          </CardTitle>
                        </CardHeader>
                        <CardContent className="p-3 pt-0">
                          <p className="text-muted-foreground text-sm">
                            Switch to your existing space below
                            {!!currentSpace.configuration?.public && (
                              <>
                                , or{" "}
                                <a
                                  href="#"
                                  onClick={() => {
                                    joinMindspaceMutation({
                                      variables: {
                                        mindspaceId: currentSpace.id,
                                      },
                                    })
                                      .then(() => {
                                        revalidate();
                                      })
                                      .catch(() => {
                                        toast({
                                          variant: "destructive",
                                          title: "Something went wrong",
                                          description:
                                            "Please try again later.",
                                        });
                                      });
                                  }}
                                  className="text-foreground underline hover:text-foreground/80"
                                >
                                  join {currentSpace.name}
                                </a>
                              </>
                            )}
                            .
                          </p>
                        </CardContent>
                      </Card>
                    )}
                  {authenticated &&
                  (spaces.length > 1 ||
                    !spaces.find((x) => x.id === currentSpace.id)) ? (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          className="flex w-full items-center gap-x-2 px-3 py-1.5 typography-detail rounded-md my-4 h-auto bg-slate-900"
                        >
                          <Avatar className="shrink-0 h-4 w-4 rounded-sm">
                            <AvatarImage src={currentSpace.images.thumbnail} />
                          </Avatar>
                          <span className="sr-only text-left">
                            Change space
                          </span>
                          <div className="line-clamp-1 grow w-full text-left">
                            {currentSpace.name}
                          </div>
                          <ChevronUpDownIcon className={"h-8 w-8"} />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className="w-56  max-h-[300px] md:max-h-[400px] lg:max-h-[600px] overflow-auto">
                        <DropdownMenuLabel>Spaces</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        {spaces.map((space) => {
                          return (
                            <DropdownMenuCheckboxItem
                              key={space.uniqueName}
                              checked={
                                space.uniqueName === currentSpace.uniqueName
                              }
                              className="flex justify-between"
                              onCheckedChange={() => {
                                window.location.href = config.appUrl.replace(
                                  currentSpace.uniqueName,
                                  space.uniqueName,
                                );
                              }}
                            >
                              <span> {space.name}</span>
                              <img
                                className="h-6 w-6 rounded-sm bg-gray-50"
                                src={space.images.thumbnail}
                                alt={space.name}
                              />
                            </DropdownMenuCheckboxItem>
                          );
                        })}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  ) : (
                    <>
                      <div className="flex w-full items-center gap-x-2 px-3 py-1.5 typography-detail rounded-md my-4 h-auto">
                        <Avatar className="shrink-0 h-4 w-4 rounded-sm">
                          <AvatarImage src={currentSpace.images.thumbnail} />
                        </Avatar>
                        <div className="line-clamp-1 grow w-full text-left">
                          {currentSpace.name}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </SheetContent>
          </Sheet>
          <Button
            variant={"ghost"}
            className="-m-2.5 p-2.5 xl:hidden"
            onClick={toggleCommandPalette}
          >
            <span className="sr-only">Open search</span>
            <SearchIcon className="h-6 w-6" />
          </Button>
        </div>
        <div className="text-sm font-semibold leading-6">
          <Link to={"/"}>
            <Logo className="h-6" iconOnly={true} />
          </Link>
        </div>

        <div className="flex flex-row shrink-0 space-x-2 items-center">
          {user && (
            <>
              <Notifications container={container} iconClassName={"h-7 w-7"} />

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <a
                    href="#"
                    className="h-8 w-8"
                    aria-label="User profile menu"
                  >
                    <span className="sr-only">Your profile</span>
                    <img
                      className="h-8 w-8 bg-gray-50 rounded-full"
                      src={user?.profilePictureURL}
                      alt={`${user?.username}'s profile picture`}
                    />
                  </a>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuLabel>My Account</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem
                      onClick={() => navigate(`/profile/${user?.username}`)}
                    >
                      <UserCircleIcon className="mr-2 h-4 w-4" />
                      <span>Profile</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => navigate("/settings")}>
                      <CogIcon className="mr-2 h-4 w-4" />
                      <span>Settings</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="ms-intercom-launcher">
                      <HelpCircleIcon className="mr-2 h-4 w-4" />
                      <span>Help</span>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onClick={() => navigate("/logout")}>
                    <LogOutIcon className="mr-2 h-4 w-4" />
                    <span>Logout</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </>
          )}
          {!user && (
            <>
              <Button variant={"secondary"} size="sm" asChild>
                <Link to={`/login?redirectUrl=${location.pathname}`}>
                  Login
                </Link>
              </Button>
            </>
          )}
        </div>
      </div>

      {/* Static sidebar for desktop */}
      <div className="dark hidden md:shrink-0 md:inset-y-0 md:z-50 md:flex md:w-20 xl:w-[220px] md:flex-col text-foreground">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-border shadow bg-card px-2 xl:px-4 ">
          <div className="hidden xl:flex items-center text-xl gap-x-3 font-medium h-20 flex-row shrink-0  xl:px-4  w-full">
            <Link to={"/"}>
              <Logo className="h-6 w-[102px]" />
            </Link>
          </div>
          <div
            className={
              "flex xl:hidden justify-center shrink-0 items-center px-2 h-20"
            }
          >
            <Link to={"/"}>
              <Logo className="h-6" iconOnly={true} />
            </Link>
          </div>
          <nav className="flex flex-1 flex-col">
            <div role="list" className="flex flex-1 flex-col gap-y-2 list-none">
              <div role="list" className="list-none flex flex-col gap-y-2">
                {navigation
                  .filter((x) => x.available(currentSpace, user))
                  .map((item) => (
                    <MenuItem item={item} key={item.href} />
                  ))}
              </div>
            </div>
            <div className="mt-auto">
              {authenticated &&
                !spaces.find((x) => x.id === currentSpace.id) && (
                  <Card className="light hidden xl:block">
                    <CardHeader className="p-3">
                      <CardTitle className="flex flex-row items-center gap-1 text-warning">
                        <MessageCircleWarningIcon className="h-5" />
                        <span>Not a member</span>
                      </CardTitle>
                    </CardHeader>
                    <CardContent className="p-3 pt-0">
                      <p className="text-muted-foreground text-sm">
                        Switch to your existing space below
                        {!!currentSpace.configuration?.public && (
                          <>
                            , or{" "}
                            <a
                              href="#"
                              onClick={() => {
                                joinMindspaceMutation({
                                  variables: {
                                    mindspaceId: currentSpace.id,
                                  },
                                })
                                  .then(() => {
                                    revalidate();
                                  })
                                  .catch(() => {
                                    toast({
                                      variant: "destructive",
                                      title: "Something went wrong",
                                      description: "Please try again later.",
                                    });
                                  });
                              }}
                              className="text-foreground underline hover:text-foreground/80"
                            >
                              join {currentSpace.name}
                            </a>
                          </>
                        )}
                        .
                      </p>
                    </CardContent>
                  </Card>
                )}
              {authenticated &&
              (spaces.length > 1 ||
                !spaces.find((x) => x.id === currentSpace.id)) ? (
                <DropdownMenu>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="ghost"
                              className="flex w-full items-center gap-x-2 px-2.5 py-1 typography-detail rounded-md my-4 h-auto bg-slate-900"
                            >
                              <Avatar className="shrink-0 h-4 w-4 rounded-sm">
                                <AvatarImage src={currentSpace.icon} />
                              </Avatar>
                              <span className="sr-only text-left">
                                Change space
                              </span>
                              <div className="hidden xl:block line-clamp-1 grow w-full text-left">
                                {currentSpace.name}
                              </div>
                              <ChevronUpDownIcon className={"h-8 w-8"} />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="w-56  max-h-[300px] md:max-h-[400px] lg:max-h-[600px] overflow-auto">
                            <DropdownMenuLabel>Spaces</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            {spaces.map((space) => {
                              return (
                                <DropdownMenuCheckboxItem
                                  key={space.uniqueName}
                                  checked={
                                    space.uniqueName === currentSpace.uniqueName
                                  }
                                  className="flex justify-between"
                                  onCheckedChange={() => {
                                    window.location.href =
                                      config.appUrl.replace(
                                        currentSpace.uniqueName,
                                        space.uniqueName,
                                      );
                                  }}
                                >
                                  <span>{space.name}</span>
                                  <img
                                    className="h-6 w-6 rounded-sm bg-gray-50"
                                    src={space.icon}
                                    alt={space.name}
                                  />
                                </DropdownMenuCheckboxItem>
                              );
                            })}
                          </DropdownMenuContent>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <span className="typography-detail">Change space</span>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </DropdownMenu>
              ) : (
                <div className="flex justify-center w-full">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="flex items-center gap-x-2 px-2.5 py-1 typography-detail rounded-md my-4 h-auto max-w-[90%]">
                          <Avatar className="shrink-0 h-4 w-4 rounded-sm">
                            <AvatarImage src={currentSpace.icon} />
                          </Avatar>
                          <div className="hidden xl:block line-clamp-1 grow w-full text-left">
                            {currentSpace.name}
                          </div>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <span className="typography-detail">
                          {currentSpace.name}
                        </span>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              )}
            </div>
          </nav>
        </div>
      </div>

      <main
        className="relative w-full h-[calc(100dvh-8rem)] md:h-full grow"
        ref={setContainer}
      >
        {children}
      </main>
    </div>
  );
}
function MenuItem({
  item,
  className,
  closeSidebar,
}: {
  item: NavigationItem;
  className?: string;
  closeSidebar?: () => any;
}) {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const Icon = item.icon;
  const id = `nav_${item.name.toLowerCase()}`;

  const content = React.useMemo(() => {
    if (item.href) {
      return (
        <Link to={item.href} id={id}>
          <Icon
            className={cn("h-4 w-4 md:h-5 md:w-5 shrink-0")}
            aria-hidden="true"
          />
          <span className="block md:hidden xl:block typography-small">
            {item.name}
          </span>
        </Link>
      );
    }
    return (
      <span id={id}>
        <Icon
          className={cn("h-4 w-4 md:h-5 md:w-5 shrink-0")}
          aria-hidden="true"
        />
        <span className="block md:hidden xl:block typography-small">
          {item.name}
        </span>
      </span>
    );
  }, [item.href, item.action]);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        asChild
      >
        <Button
          variant="ghost"
          className={cn(
            "relative h-10 w-full focus-visible:ring-0 focus-visible:ring-offset-0 disabled" +
              " justify-start md:justify-center xl:justify-start rounded-md flex space-x-2 items-center px-3",
            item.current &&
              item.current(location.pathname) &&
              "text-violet-400 hover:text-violet-400",
            "xl:hover:bg-transparent xl:hover:underline xl:group",
            className,
          )}
          onClick={() => {
            if (closeSidebar) closeSidebar();
          }}
          asChild={!!item.href}
          aria-label={item.name}
        >
          {content}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        side="right"
        onMouseLeave={handleMouseLeave}
        className={cn(
          "hidden md:flex xl:hidden dark bg-accent items-center text-foreground py-1 w-fit rounded-none border-none rounded-r-md -mx-2 h-10",
          item.current &&
            item.current(location.pathname) &&
            "text-violet-400 hover:text-violet-400",
          className,
        )}
      >
        <span className="typography-small">{item.name}</span>
      </PopoverContent>
    </Popover>
  );
}
